<template>
  <b-modal
    v-model="displayModal"
    id="modal-sm"
    no-close-on-backdrop
    cancel-variant="secondary"
    ok-only
    ok-title="Submit"
    centered
    size="xs"
    title="School Fee Payment"
    @ok="submitFee"
    @close="$emit('hide-modal', false)"
  >
    <div>
      <b-alert v-model="onFeeComplete" dismissible variant="success">
        <div class="alert-body">
          Tuition has been paid completely for this student.
        </div>
      </b-alert>
    </div>
    <b-form-group>
      <label for="firstName">First Name</label>
      <b-input-group>
        <b-form-input
          id="firstName"
          type="text"
          placeholder="Student's First Name"
          v-model="feeForm.firstName"
          @blur="checkExisting"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <label for="lastName">Last Name</label>
      <b-input-group>
        <b-form-input
          id="lastName"
          type="text"
          placeholder="Student's Last Name"
          v-model="feeForm.lastName"
          @blur="checkExisting"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <label for="studentId">Registration Number</label>
      <b-input-group>
        <b-form-input
          id="studentId"
          type="text"
          placeholder="Student's Registration Number"
          v-model="feeForm.regNumber"
          @blur="checkExisting"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <label for="studentClass">Select Class</label>
      <v-select
        id="studentClass"
        v-model="feeForm.class"
        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        label="title"
        :options="classOption"
        @input="onSelectedClass"
      />
    </b-form-group>
    <b-form-group>
      <label for="amount">School Fee Amount</label>
      <b-input-group>
        <b-form-input
          id="amount"
          type="text"
          placeholder="School Fee"
          v-model="feeForm.amount"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <label for="amount">Payer Name</label>
      <b-input-group>
        <b-form-input
          id="amount"
          type="text"
          placeholder="Parent/Guardian Name"
          v-model="feeForm.payerName"
        />
      </b-input-group>
    </b-form-group>
    <b-form-group>
      <label for="additionalInfo">Additional Details(Optional)</label>
      <b-input-group>
        <b-form-textarea
          id="additionalInfo"
          placeholder=""
          rows="3"
          v-model="feeForm.additionalNotes"
        />
      </b-input-group>
    </b-form-group>
  </b-modal>
</template>
<script>
import { 
  defineComponent,
  reactive, 
  ref, 
  watch
} from "@vue/composition-api";
import vSelect from "vue-select";
import API from "./api";

export default defineComponent({
  props: {
    showModal: {
      type: Boolean,
      required: true,
    },
    classes: {
      type: Array,
      required: true,
    },
  },
  components: {
    vSelect,
  },
  emits: ["hideModal"],
  setup(props) {
    const feeForm = reactive({
      firstName: "",
      lastName: "",
      amount: null,
      regNumber: "",
      payerName: "",
      additionalNotes: "",
      paymentMethod: "Cash",
      class: "",
    });

    const displayModal = ref(props.showModal);
    const classOption = ref(props.classes);
    const onFeeComplete = ref(false);

    const tuitionCap = ref(0);

    const yearInfo = reactive({ currentFees: 0 });
    const currentlyPending = ref([]);

    const existingCheck = ref({})

    // For testing purposes
    const onSelectedClass = () => {
      if (feeForm.class) {
        tuitionCap.value = feeForm.class.tuition
        let finalAmt = parseInt(tuitionCap.value) - parseInt(existingCheck.value.amount);
        feeForm.amount = finalAmt;
        if (finalAmt === 0) {
          onFeeComplete.value = true;
        }
        onFeeComplete.value = true;
      } else {
        tuitionCap.value = 0
      }
      // console.log(JSON.stringify(feeForm.class, undefined, 2))
    }

    const submitFee = async (e) => {
      console.log("testing ", e);
      let feeStatus;

      if (currentlyPending.value.length > 0) {
        if (
          yearInfo.currentFees <=
          parseInt(feeForm.amount) + parseInt(currentlyPending.value[0].amount)
        ) {
          feeStatus = 2;
        } else {
          feeStatus = 3;
        }
      }
      if (currentlyPending.value.length < 1) {
        if (yearInfo.currentFees <= parseInt(feeForm.amount)) {
          feeStatus = 2;
        } else {
          feeStatus = 3;
        }
      }

      if (currentlyPending.value.length < 1) {
        const { data, success } = await API.manualPayment({
          studentFirstName: feeForm.firstName,
          studentLastName: feeForm.lastName,
          class: feeForm.class,
          amount: feeForm.amount,
          paymentMethod: "MTN Mobile Money",
          status: feeStatus,
          currency: "XAF", // Not found in Backend but it is compulsory to store @Rolland
        });
        if (success) {
          // showForm.value = false;
          console.log(data);
        }
      } else {
        let finalAmt =
          parseInt(currentlyPending.value[0].amount) + parseInt(feeForm.amount);

        const { success } = await API.manualFeeUpdate({
          id: currentlyPending.value[0]._id,
          studentFirstName: feeForm.firstName,
          studentLastName: feeForm.lastName,
          class: feeForm.class,
          amount: finalAmt,
          amountPaid: feeForm.amount,
          paymentMethod: "MTN Mobile Money",
          status: feeStatus,
          currency: "XAF",
        });
        if (success) {
          // showForm.value = false;
        }
      }
    };

    const checkExisting = async () => {
      if (feeForm.firstName && feeForm.lastName && feeForm.regNumber) {
        let params = {
          studentFirstName: feeForm.firstName,
          studentLastName: feeForm.lastName,
          studentId: feeForm.regNumber
        };

        const { data, success } = await API.getSpecific(params);

        if (success) {
          existingCheck.value = data.fees
          // console.log(JSON.stringify(feeForm.class, undefined, 2));

          // currentlyPending.value.push(data.fees);
        }

        if (success === false) {
          existingCheck.value = {}
        }
      }
    };

    watch(props, (newValue, prevValue) => {
      // console.log(newValue);
      displayModal.value = newValue.showModal;
    });

    return {
      feeForm,
      displayModal,
      submitFee,
      checkExisting,
      classOption,
      onSelectedClass,
      onFeeComplete
    };
  },
});
</script>
<style lang="scss" scoped>
@import "@core/scss/vue/libs/vue-select.scss";
</style>
