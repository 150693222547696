<template>
  <div>
    <b-card>
      <b-row>
        <b-col cols="12">
          <b-table
            id="feesList"
            striped
            responsive
            :fields="fields"
            :items="txnData.list"
            :per-page="perPage"
            :current-page="1"
            show-empty
          >
            <!-- Show table busy when loading -->
            <template #table-busy>
              <div class="text-center text-danger my-2">
                <b-spinner class="align-middle mr-1"></b-spinner>
                <strong>Loading...</strong>
              </div>
            </template>
            <!-- Custom header formatting -->
            <!-- <template #head()="scope">
              <div class="text-nowrap text-primary">
                {{ scope.column }}
              </div>
            </template> -->
            <!-- A virtual column -->
            <!-- <template #cell(index)="data">
              <div v-if="!data.item.isBlank">
                {{ data.index + 1 + ((txnData.currentPage - 1) * 10) }}
              </div>
            </template> -->

            <!-- A custom formatted column -->
            <template #cell(name)="data">
              <div v-if="data.item._id">
                {{ data.item.studentFirstName + ' ' + data.item.studentLastName }}
              </div>
            </template>

            <!-- A custom formatted column -->
            <template #cell(studentClass)="data">
              <div v-if="!data.item.isBlank">
                {{ data.value || data.item.class }}
              </div>
            </template>

            <template #cell(amount)="data">
              <div v-if="!data.item.isBlank && formatFee(data.value, 'XAF', 'en-US') != 0">
                <span pill :class="'text-' + statusVariant(data.item.status)">
                  {{ formatFee(data.value, 'XAF', 'en-US') }} <span class="currency">XAF</span>
                </span>
              </div>

            </template>

            <!-- A virtual composite column -->
            <template #cell(status)="data">
              <div v-if="!data.item.isBlank">
                <b-badge pill :variant="statusVariant(data.value)">
                  <span v-if="data.value == 2">Complete</span>
                  <span v-if="data.value == 3">Incomplete</span>
                </b-badge>
              </div>
            </template>

            <!-- Optional default data cell scoped slot -->
            <template #cell(updatedAt)="data">
              <div v-if="data.item.updatedAt">
                {{ dayjs(data.value).format('MMMM D, YYYY h:mm A') }}
              </div>
            </template>
          </b-table>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
// TODO: Add Invoice references
import utils from "@/utils/index"
import { BTable, BProgress, BBadge } from "bootstrap-vue";
import {
  computed,
  defineComponent,
  reactive,
  ref,
  watch
} from "@vue/composition-api";
import * as dayjs from 'dayjs'
import API from "./api";

export default defineComponent({
  name: "FeesList",
  components: {
    BTable,
    BProgress,
    BBadge,
  },
  props: {
    txns: {}
  },
  data() {
    return{
      txnData: {
        list: [],
        total: 10,
        hasMore: false,
        currentPage: 1
      }
    }
  },
  mounted() {
    this.txnData.list = utils.completeTable();
  },
   watch: {
    txns: function(e) {
      this.txnData = e;
    }
  },
  setup(props) {
    const isBusy = ref(true);
    const fields = reactive([
      // A virtual column that doesn't exist in txnData
      // "index",
      // A column that needs custom formatting
      { key: "name", label: "Student Name" },
      // A regular column
      { key: "studentClass", label: "Class" },
      // A regular column
      { key: "amount", label: "Amount (FCFA)" },
      // A virtual column made up from two fields
      { key: "status", label: "Status" },
      { key: "updatedAt", label: "Last Payment" },
    ]);
    const perPage = ref(10);
    const feeCap = ref(5000);

    const formatFee = (amt, curr, locale) => utils.formatCurrency(amt, curr, locale)

    const statusVariant = value => {
      if (value == 3) {
        return 'warning'
      }

      if (value == 2) {
        return 'success'
      }

      if (value == 1) {
        return 'danger'
      }
    }

    return {
      isBusy,
      fields,
      perPage,
      statusVariant,
      dayjs,
      formatFee
    };
  },
});
</script>
<style scoped>
.currency {
  font-size: smaller;
}
</style>