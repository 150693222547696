<template>
  <div>
    <b-card no-body>
      <!-- <b-card-header class="pb-50">
            <h5>Filters</h5>
          </b-card-header> -->
      <b-card-body>
        <b-row align-h="center">
          <b-col>
            <label>Start and End Dates</label>
            <flat-pickr
              v-model="searchItemDateRange"
              class="form-control"
              :config="{ mode: 'range' }"
              name="searchItemDateRange"
            />
          </b-col>
          <b-col>
            <label>Status</label>
            <v-select
              v-model="searchItemStatus"
              :options="searchStatus"
              label="title"
            />
          </b-col>

          <b-col cols="12" md="4" class="mb-md-0 mb-2">
            <label>Student Name</label>
            <div class="d-flex align-items-center justify-content-end">
              <b-input-group>
                <b-form-input
                  v-model="searchItemName"
                  placeholder="eg: John Doe"
                />
              </b-input-group>
            </div>
          </b-col>
          <b-col>
            <!-- needed for alignment with input boxes on the side -->
            <label></label>
            <div>
              <b-button @click="searchQuery()" variant="primary"
                >Search</b-button
              >
            </div>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
    
    <b-card>
      <b-row>
        <b-col cols="6">
            <div class="d-flex h-100 pl-2">
              <div class="d-flex align-items-center">
                <h5>Tuition: {{ currentAY.startMonth }} {{ currentAY.startYear }}/{{ currentAY.endYear }}</h5>
              </div>
            </div>
        </b-col>
        <b-col cols="6">
          <div class="d-flex justify-content-end pr-2">
            <b-button class="mr-1" variant="outline-primary" @click="toggleManualModal(true)">
              Cash Payment
            </b-button>
            <b-button class="mr-1" variant="outline-primary" @click="exportData(true)">
              Export
            </b-button>
            <b-button-group>
              <b-button :variant="mode == 2? 'primary' : 'outline-primary'" @click="switchMode(2)">
                Fees
              </b-button>
              <b-button :variant="mode == 1? 'primary' : 'outline-primary'" @click="switchMode(1)">
                Transactions
              </b-button>
            </b-button-group>
          </div>
        </b-col>
        <b-col cols="12">
          <fees-list :txns="txnData" />
        </b-col>
        <b-col cols="12">
          <b-pagination
            v-model="txnData.currentPage"
            :total-rows="txnData.total"
            :per-page="perPage"
            align="center"
            size="sm"
            class="my-0"
            aria-controls="feesList"
            @change="newPage"
          />
        </b-col>
      </b-row>
    </b-card>
    <manual-pay-modal :show-modal="manualModal" :classes="allClasses" @hide-modal="toggleManualModal" />
  </div>
</template>

<script>
import { BTable, BProgress, BBadge } from "bootstrap-vue";
import {
  defineComponent,
  reactive,
  ref,
  watch,
  onMounted
} from "@vue/composition-api";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import utils from "@/utils/index"
import API from "./api";
import FeesList from "./FeesList.vue";
import ManualPayModal from "./modalManualPay.vue"

export default defineComponent({
  components: {
    BTable,
    BProgress,
    BBadge,
    vSelect,
    flatPickr,
    FeesList,
    ManualPayModal
  },
  data() {
    return{
      mode: 1,
      currentAY: {},
      allClasses: []
    }
  },
  mounted() {
    this.txnData.list = utils.completeTable()
    this.getFeesList();
    this.getCurrentAcademicYear();
  },
  watch: {
    searchItemDateRange: function(data) {
      if(!data) {
        this.searchQuery();
      }
    },
    searchItemStatus: function(data) {
      if(!data) {
        this.searchQuery();
      }
    },
    searchItemName: function (data) {
      if(!data.trim().length) {
        this.searchQuery();
      }
    }
  },
  methods: {
    switchMode(mode) {
      this.mode = mode;
      this.getFeesList(1);
    },
    async getCurrentAcademicYear() {
      const { data, success } = await API.getCurrentAcademicYear();

      if (success) {
        this.currentAY = data.academics[0];

        for (let x = 0; x < data.academics[0].class.length; x++) {
          const thisClass = data.academics[0].class[x];
          let obj = {
            title: thisClass.classLabel,
            id: thisClass._id,
            status: thisClass.status,
            tuition: thisClass.tuition,
            cycle: thisClass.cycle,
            name: thisClass.name
          }
          
          this.allClasses.push(obj);
        }
        this.allClasses = data.academics[0].class;
      }
    },
    getFeesList: async function(pageNum = 1) {
      let data = {};
      let success = false;
      if(this.mode == 1) {
        API.getMyTransactions({...this.searchParam, pagNum: pageNum}).then(res=>{
          if(res.success) {
            const { total = 10, hasMore = false } = res.data.transactions
            this.txnData.list = this.completeTable( res.data.transactions.list || []);
            this.txnData.total = total;
            this.txnData.hasMore = hasMore;
            this.txnData.currentPage = pageNum;
            this.txnData = {...this.txnData};
          }
        })
      }else{
       API.getFeePayment({...this.searchParam, pagNum: pageNum}).then(res=>{
          if(res.success) {
            const { total = 10, hasMore = false } = res.data.fees
            this.txnData.list = this.completeTable( res.data.fees.list || []);
            this.txnData.total = total;
            this.txnData.hasMore = hasMore;
            this.txnData.currentPage = pageNum;
            this.txnData = {...this.txnData};
          }
        })
      }
    },
    searchQuery: function() {
      this.searchParam = {};

      if(this.searchItemName.trim().length) {
        this.searchParam.user = this.searchItemName.trim();
      }
      if(this.searchItemStatus && this.searchItemStatus.code) {
        this.searchParam.status = this.searchItemStatus.code;
      }
      if(this.searchItemDateRange && this.searchItemDateRange) {
        let dates = this.searchItemDateRange.split(' to ');
        if(dates.length == 1) {
          dates[1] = dates[0];
        }
        this.searchItemDateRange = dates[0] + ' to ' + dates[1];

        this.searchParam.startDate = dates[0] + ' 00:00:00';
        this.searchParam.endDate = dates[1] + ' 23:59:59';
      }

      this.getFeesList();

    },

    newPage: function(e) {
      this.getFeesList(e);
    }

  },

  setup() {
    const completeTable = function(data = []) {
      if(data.length >= 10) {
        return data;
      }
      const boundary = 10 - data.length;
      for(let i = 0; i < boundary; i++) {
        data.push({});
      }
      return data;
    }

    let searchParam = reactive({});
    const txnData = reactive({
      list: completeTable(),
      total: 0,
      hasMore: false,
      currentPage: 1
    });

    const perPage = ref(10);
    const searchItemDateRange = ref("");
    const searchItemStatus = ref("");
    const searchItemName = ref("");
    const searchStatus = reactive([
      {
        title: "Normal",
        code: 1,
      },
      {
        title: "Featured (Tier 1)",
        code: 100,
      },
      {
        title: "Featured (Tier 2)",
        code: 101,
      },
    ]);

    const manualModal = ref(false);

    const toggleManualModal = params => {
      if (params) {
        manualModal.value = true;
      } else {
        manualModal.value = false;
      }
    }

    return {
      searchItemDateRange,
      searchItemStatus,
      searchItemName,
      searchStatus,
      manualModal,
      txnData,
      searchParam,
      completeTable,
      toggleManualModal,
      perPage,
    };
  },
});
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>