import request from "@/services/request"
import baseUrl from "@/services/baseUrl"

let servicePathTxn = "/ji036/v1/transaction";
let servicePathFee = "/ji036/v1/fee";
let servicePathAY = "/ji036/v1/academicYr";

const mpBaseUrlTxn = baseUrl.main + servicePathTxn;
const mpBaseUrlFee = baseUrl.main + servicePathFee;
const mpBaseUrlAY = baseUrl.main + servicePathAY;

export default {
    // create transactions
    createTransaction: (params={}) => request.post(mpBaseUrlTxn, params),

    // get transactions
    getMyTransactions: (params={}) => request.post(mpBaseUrlTxn + '/all', params),


    // get fees
    getFeePayment: (params={}) => request.post(mpBaseUrlFee + '/all', params),

    manualPayment: (params={}) => request.post(mpBaseUrlFee + '/new', params),
    manualFeeUpdate: (params={}) => request.post(mpBaseUrlFee + '/new', params),

    // Check Existing student if fee has been paid
    getSpecific: (params={}) => request.post(mpBaseUrlFee + '/unique', params),

    // Get running academic year
    getCurrentAcademicYear: (params={}) => request.get(mpBaseUrlAY + '/current', params)
}